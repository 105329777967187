<template>
  <div id="app">
    <!-- MAIN HEADER MENU -->
    <div class="container-indu container-menu">
      <!-- HEADER MENU MOBILE -->
      <main-header-menu>
      </main-header-menu>
    </div>

    <!-- HEADER MENU DESKTOP -->
    <main-header-menu-desktop 
        data-aos="fade"
        data-aos-duration="500"
        data-aos-delay="300">
    </main-header-menu-desktop>
      <div class="loader-page"></div>

      <transition name="otro">
        <router-view/>
      </transition>
  </div>
</template>

<script>
export default {
    name: 'App',
    data() {
      return {

      }
    }
}
</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

.otro-enter-active, .otro-leave-active {
    transition: opacity .5s;
}
.otro-enter, .otro-leave-to /* .fade-leave-active below version 2.1.8 */ {
    // opacity: 0;
    background-color: red !important;
    &:before {
        content: '';
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;
        background-color: rgba(220, 220, 220, 1);
    }
    
    @keyframes xxx {
        from { bottom: inherit }
        to { bottom: 0 }
    }
}


.container-menu {
    margin: 0% auto;

    @media (min-width: 1900px) {
      max-width: 1800px !important;
    }
}
</style>
