<template>
    <div class="content-main-menu">
        <div class="logo-icon-menu" ref="logo_icon_menu">
            <!-- <img src="@/assets/logo-industrias-pico.png" class="logo" alt="Industrias pico"> -->
            <span
                @click="toggleShowMenu()" 
                class="material-icons icon-menu">menu</span>
        </div>
        
        <transition name="slipright">
            <div
                v-show="show_menu" 
                class="main-menu">
                <div class="top-bar">
                    <div
                        @click="toggleShowMenu()" 
                        class="icon-close">
                        Cerrar
                    </div>
                </div>

                <ul>
                    <li>
                        <router-link 
                            tag="a" 
                            to="/"
                            @click.native="toggleShowMenu()" >
                            Inicio
                        </router-link>
                    </li>
                    <li>
                        <router-link 
                            tag="a" 
                            :to="{ name: 'offers' }"
                            @click.native="toggleShowMenu()">
                            Ofertas
                        </router-link>
                    </li>
                    <!-- Muebles de Oficina -->
                    <li class="drop-item">
                        <div class="li-expand">
                            <router-link
                                tag="span" 
                                :to="{ name: 'office-furniture' }"
                                @click.native="toggleShowMenu()" >
                                Muebles de Oficina
                            </router-link>
                            <span 
                                @click="toggleDropMenu('drop_menu_1')"
                                class="material-icons expand-more">expand_more</span>
                        </div>

                        <!-- DROP MENU -->
                        <ul
                            ref="drop_menu_1" 
                            class="drop-menu">
                            <div class="drop-menu-content">
                                <!-- <router-link tag="a" :to="{ name: 'interior-architecture' }">
                                    Arquitectura interior
                                </router-link> -->
                                <li class="drop-item">
                                    <div class="li-expand">
                                        <router-link 
                                            tag="span" 
                                            :to="{ name: 'office-chairs' }"
                                            @click.native="toggleShowMenu()">
                                            Sillas para oficinas
                                        </router-link>
                                        <span
                                            @click="toggleDropMenu('drop_menu_1_1')" 
                                            class="material-icons expand-more">expand_more</span>
                                    </div>

                                    <!-- DROP MENU -->
                                    <div
                                        ref="drop_menu_1_1" 
                                        class="drop-menu drop-menu-left">
                                        <ul class="drop-menu-content">
                                            <li>
                                                <router-link 
                                                    tag="a"
                                                    :to="{ path: '/muebles-de-oficina/sillas-para-oficinas/sillas-presidenciales' }"
                                                    @click.native="toggleShowMenu()">
                                                    Sillas Presidenciales
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link 
                                                    tag="a" 
                                                    :to="{ path: '/muebles-de-oficina/sillas-para-oficinas/sillas-gerenciales' }"
                                                    @click.native="toggleShowMenu()">
                                                    Sillas Gerenciales
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link 
                                                    tag="a" 
                                                    :to="{ path: '/muebles-de-oficina/sillas-para-oficinas/sillas-operativas' }"
                                                    @click.native="toggleShowMenu()">
                                                    Sillas Operativas
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link 
                                                    tag="a" 
                                                    :to="{ path: '/muebles-de-oficina/sillas-para-oficinas/sillas-interlocutoras' }"
                                                    @click.native="toggleShowMenu()">
                                                    Sillas Interlocutoras
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link 
                                                    tag="a" 
                                                    :to="{ path: '/muebles-de-oficina/sillas-para-oficinas/sillas-para-colectivos' }"
                                                    @click.native="toggleShowMenu()">
                                                    Sillas para Colectivos
                                                </router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <router-link 
                                        tag="a" 
                                        :to="{ name: 'furniture-reception' }"
                                        @click.native="toggleShowMenu()">
                                        Muebles para Recepción
                                    </router-link>
                                </li>
                                <li>
                                    <router-link 
                                        tag="a" 
                                        :to="{ name: 'waiting-room' }"
                                        @click.native="toggleShowMenu()">
                                        Salas de Espera
                                    </router-link>
                                </li>
                                <li>
                                    <router-link 
                                        tag="a" 
                                        :to="{ name: 'collaborative-spaces' }"
                                        @click.native="toggleShowMenu()">
                                        Espacios Colaborativos
                                    </router-link>
                                </li>
                                <li>
                                    <router-link 
                                        tag="a" 
                                        :to="{ name: 'meeting-rooms' }"
                                        @click.native="toggleShowMenu()">
                                        Salas de Juntas
                                    </router-link>
                                </li>
                                <li>
                                    <router-link 
                                        tag="a" 
                                        :to="{ name: 'office-divisions' }"
                                        @click.native="toggleShowMenu()">
                                        Divisiones de Oficina
                                    </router-link>
                                </li>
                                <li>
                                    <router-link 
                                        tag="a" 
                                        :to="{ name: 'operative-furniture' }"
                                        @click.native="toggleShowMenu()">
                                        Muebles Operativos
                                    </router-link>
                                </li>
                                <li>
                                    <router-link 
                                        tag="a" 
                                        :to="{ name: 'managerial-furniture' }"
                                        @click.native="toggleShowMenu()">
                                        Muebles Gerenciales
                                    </router-link>
                                </li>
                            </div>
                        </ul>
                    </li>
                    <!-- Estanterías metálicas -->
                    <li class="drop-item">
                        <div class="li-expand">
                        <router-link 
                            tag="span" 
                            :to="{ name: 'metal-shelves' }"
                            @click.native="toggleShowMenu()">
                                Estanterías Metálicas
                            </router-link>
                            <span
                                @click="toggleDropMenu('drop_menu_2')" 
                                class="material-icons expand-more">expand_more</span>
                        </div>
                       <!-- DROP MENU -->
                        <div
                            ref="drop_menu_2" 
                            class="drop-menu">
                            <ul class="drop-menu-content">
                                <li class="drop-item">  
                                    <div class="li-expand">
                                        <router-link 
                                            tag="span" 
                                            :to="{ name: 'heavy-shelves' }"
                                            @click.native="toggleShowMenu()">
                                            Estantería Pesada
                                        </router-link>
                                        <span 
                                            @click="toggleDropMenu('drop_menu_2_1')"
                                            class="material-icons expand-more">expand_more</span>
                                    </div>
                                    <!-- DROP MENU -->
                                    <div
                                        ref="drop_menu_2_1" 
                                        class="drop-menu drop-menu-left">
                                        <ul class="drop-menu-content">
                                            <li>
                                                <router-link 
                                                    tag="a"
                                                    :to="{ path: '/estanteria-metalica/estanteria-pesada/rack-selectivo' }"
                                                    @click.native="toggleShowMenu()">
                                                    Rack Selectivo
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link 
                                                    tag="a"
                                                    :to="{ path: '/estanteria-metalica/estanteria-pesada/carga-compacta-drive-in' }"
                                                    @click.native="toggleShowMenu()">
                                                    Carga Compacta Drive In
                                                </router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="drop-item">
                                    <div class="li-expand">
                                        <router-link 
                                            tag="a" 
                                            :to="{ name: 'rack-picking' }"
                                            @click.native="toggleShowMenu()">
                                            Rack Picking - Carga Manual
                                        </router-link>
                                        <span 
                                            @click="toggleDropMenu('drop_menu_rack_picking')"
                                            class="material-icons expand-more">expand_more</span>
                                    </div>
                                    <!-- DROP MENU -->
                                    <div
                                        ref="drop_menu_rack_picking" 
                                        class="drop-menu drop-menu-left">
                                        <ul class="drop-menu-content">
                                            <li>
                                                <router-link 
                                                    tag="a"
                                                    :to="{ path: '/estanteria-metalica/rack-picking-carga-manual/estanteria-liviana' }"
                                                    @click.native="toggleShowMenu()">
                                                    Estantería liviana
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link 
                                                    tag="a"
                                                    :to="{ path: '/estanteria-metalica/rack-picking-carga-manual/estanteria-ranurada' }"
                                                    @click.native="toggleShowMenu()">
                                                    Estantería ranurada
                                                </router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <router-link 
                                        tag="a" 
                                        :to="{ name: 'mezzanines' }"
                                        @click.native="toggleShowMenu()">
                                        Mezzanines y Entrepisos
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <!-- Muebles de supermercado -->
                    <li class="drop-item">
                        <div class="li-expand">
                        <router-link 
                            tag="span" 
                            :to="{ name: 'supermarket-furniture' }"
                            @click.native="toggleShowMenu()">
                                Muebles de supermercado
                            </router-link>
                            <span
                                @click="toggleDropMenu('drop_menu_3')" 
                                class="material-icons expand-more">expand_more</span>
                        </div>
                        <!-- DROP MENU -->
                        <div
                            ref="drop_menu_3" 
                            class="drop-menu">
                            <ul class="drop-menu-content">
                                <li class="drop-item">  
                                    <div class="li-expand">
                                        <router-link 
                                            tag="span" 
                                            :to="{ name: 'gondolas' }"
                                            @click.native="toggleShowMenu()">
                                            Góndolas
                                        </router-link>
                                        <span 
                                            @click="toggleDropMenu('drop_menu_3_1')"
                                            class="material-icons expand-more">expand_more</span>
                                    </div>
                                    <!-- DROP MENU -->
                                    <div
                                        ref="drop_menu_3_1" 
                                        class="drop-menu drop-menu-left">
                                        <ul class="drop-menu-content">
                                            <li>
                                                <router-link tag="a" 
                                                    :to="{ path: '/muebles-de-supermercado/gondolas/isla' }"
                                                    @click.native="toggleShowMenu()">
                                                    Isla
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link tag="a" 
                                                    :to="{ path: '/muebles-de-supermercado/gondolas/lateral' }"
                                                    @click.native="toggleShowMenu()">
                                                    Lateral
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link tag="a" 
                                                    :to="{ path: '/muebles-de-supermercado/gondolas/verdulero' }"
                                                    @click.native="toggleShowMenu()">
                                                    Verdulero
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link tag="a" 
                                                    :to="{ path: '/muebles-de-supermercado/gondolas/accesorios' }"
                                                    @click.native="toggleShowMenu()">
                                                    Accesorios
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link tag="a" 
                                                    :to="{ path: '/muebles-de-supermercado/gondolas/canastilla' }"
                                                    @click.native="toggleShowMenu()">
                                                    Canastilla
                                                </router-link>
                                            </li>
                                            <li>
                                            <router-link tag="a" 
                                                    :to="{ path: '/muebles-de-supermercado/gondolas/escalonada' }"
                                                    @click.native="toggleShowMenu()">
                                                    Escalonada
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link tag="a" 
                                                    :to="{ path: '/muebles-de-supermercado/gondolas/punto-de-pago' }"
                                                    @click.native="toggleShowMenu()">
                                                    Punto de pago
                                                </router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <!-- Archivadores -->
                                <li class="drop-item">  
                                    <div class="li-expand">
                                        <router-link 
                                            tag="span" 
                                            :to="{ name: 'cabinets' }"
                                            @click.native="toggleShowMenu()">
                                            Archivadores
                                        </router-link>
                                        <span 
                                            @click="toggleDropMenu('drop_menu_4_1')"
                                            class="material-icons expand-more">expand_more</span>
                                    </div>
                                    <!-- DROP MENU -->
                                    <div
                                        ref="drop_menu_4_1" 
                                        class="drop-menu drop-menu-left">
                                        <ul class="drop-menu-content">
                                            <li>
                                                <router-link tag="a" 
                                                    :to="{ path: '/muebles-de-supermercado/archivadores/gaveta' }"
                                                    @click.native="toggleShowMenu()">
                                                    Gaveta
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link tag="a" 
                                                    :to="{ path: '/muebles-de-supermercado/archivadores/aereo' }"
                                                    @click.native="toggleShowMenu()">
                                                    Aéreo
                                                </router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <router-link 
                                        tag="a" 
                                        :to="{ name: 'lockers' }"
                                        @click.native="toggleShowMenu()">
                                        Lockers
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="drop-item">  
                        <div class="li-expand">
                            <router-link 
                                tag="span" 
                                :to="{ name: '' }"
                                @click.native="toggleShowMenu()">
                                Muebles especiales
                            </router-link>
                            <span 
                                @click="toggleDropMenu('drop_menu_5_1')"
                                class="material-icons expand-more">expand_more</span>
                        </div>
                        <!-- DROP MENU -->
                        <div
                            ref="drop_menu_5_1" 
                            class="drop-menu drop-menu-left">
                            <ul class="drop-menu-content">
                                <li>
                                    <router-link tag="a" 
                                        :to="{ path: '/muebles-de-supermercado/muebles-especiales/bins-de-ofertas' }"
                                        @click.native="toggleShowMenu()">
                                        Bins de Ofertas
                                    </router-link>
                                </li>
                                <li>
                                    <router-link tag="a" 
                                        :to="{ path: '/muebles-de-supermercado/muebles-especiales/canastilla-para-liquidacion' }"
                                        @click.native="toggleShowMenu()">
                                        Canastilla para Liquidación
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <router-link 
                            tag="a" 
                            :to="{ name: 'contact' }"
                            @click.native="toggleShowMenu()">
                            Contáctenos
                        </router-link>
                    </li>
                    <li
                        class="drop-item">
                        <div class="li-expand">
                            Nosotros
                            <span
                                @click="toggleDropMenu('drop_menu_4')" 
                                class="material-icons expand-more">expand_more</span>
                        </div>
                        <!-- DROP MENU -->
                        <div
                            ref="drop_menu_4" 
                            class="drop-menu">
                            <ul class="drop-menu-content">
                                <li>
                                    <router-link 
                                        @click.native="toggleShowMenu()"
                                        tag="a" 
                                        :to="{ name: 'services' }">Servicios
                                    </router-link>
                                </li>
                                <li>
                                    <router-link
                                        @click.native="toggleShowMenu()"
                                        tag="a"
                                        :to="{ name: 'contact' }">
                                        Contáctenos
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        Blog
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show_menu: false
        }
    },
    methods: {
        toggleShowMenu() {
            this.show_menu = !this.show_menu;
        },
        toggleDropMenu(drop_menu) {
            this.$refs[drop_menu].classList.toggle("show-drop-menu"); 
        },
        showDropMenu(drop_menu) {
            this.$refs[drop_menu].style.display = 'block';
        },
        hiddenDropMenu(drop_menu) {
            this.$refs[drop_menu].style.display = 'none';
        }
    },
    computed: {
        route() {
            return this.$router.app._route.name;
        }
    },
    watch: {
        route() {
            if (this.route != 'home') {
                this.$refs.logo_icon_menu.classList.add('logo-icon-menu-no-home');
            } else {
                this.$refs.logo_icon_menu.classList.remove('logo-icon-menu-no-home');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';
@import '@/assets/css/_mixins.scss';

.d-block { display: block !important }

// header {
//     border: 1px solid rgba(230,230,230,.7);
//     padding: 0.3rem;

.content-main-menu {
    position: relative;
    z-index: 55;
    // LOGO - MENU ICON 
    .logo-icon-menu-no-home {
        padding: 0px !important;

        .icon-menu {
            background-color: $main-red-1 !important;
            padding: 8px !important;
            font-size: 35px !important;
        }
    }
    .logo-icon-menu {
        // border: 1px solid red;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 10px 12px 10px 12px;

        .logo {
            width: 120px
        }

        .icon-menu {
            cursor: pointer;
            font-size: 30px;
            color: #FFFFFF
        }

        @media (min-width: 1024px) {
            justify-content: flex-end;
            .icon-menu { display: none }
        }
    }

    .main-menu {
        background-color: #FFFFFF;
        position: fixed;
        z-index: 5;
        top: 0;
        right: 0;
        width: 100%;
        max-width: 410px;
        height: 100%;
        padding: 35px 40px 25px 40px;
        overflow-y: auto;
        box-shadow: 0 5px 5px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

        .top-bar {
            box-sizing: border-box;
            padding: 10px 0px 10px 0px;

            .icon-close {
                font-size: 24px;
                letter-spacing: 1px;
                color: $main-red-1;
                position: relative;
                cursor: pointer;

                &:before {
                    content: '';
                    position: absolute;
                    width: 25px;
                    height: 2px;
                    background-color: $main-red-1;
                    top: 0;
                    left: 0;
                    margin-top: 17.5px;
                    margin-left: -40px
                }
            }

             @media (min-width: 1024px) { .icon-close { display: none } }
        }

        ul {
            margin-top: 8px;
            padding: 0px;
            list-style: none;

            a {
                // border: 1px solid blue;
                // padding: 15px 0px 15px 0px;
                font-size: 18px;
                // cursor: pointer;
                text-decoration: none;
                color: #000000;
                margin: 0px
            }

            li { 
                padding: 15px 0px 15px 0px;
                font-size: 18px;
                cursor: pointer;
            }

            .drop-item {
                position: relative;

                .li-expand {
                    display: flex;
                    align-items: center;
                    justify-content: space-between
                }

                .expand-more {
                    border-radius: 1px;
                    color: $main-red-1;
                    border: 1px solid rgba(236,67,69, .40)
                }

                .show-drop-menu { 
                    padding: 0px 0px 0px 15px !important;
                    margin-top: 15px !important;
                    max-height: 750px !important;
                    visibility: visible !important
                }

                .drop-menu {
                    padding: 0px;
                    margin: 0px;
                    max-height: 0px;
                    visibility: hidden;
                    overflow: hidden;
                    transition: all 0.5s;
                    border-left: 1px solid $main-red-1
                }
            }
        }

        @media (min-width: 1024px) {
            overflow: initial;
            position: relative;
            box-shadow: initial;

            ul {
                display: flex;
                justify-content: flex-start;

                li { margin-right: 10px }

                .drop-item {
                    overflow: initial;

                    .li-expand {
                        display: flex;
                        align-items: center;
                        justify-content: space-between
                    }

                    .expand-more { border: 0px; margin-left: 10px }

                    .drop-menu {
                        // border: 1px solid blue;
                        background-color: #FFFFFF;
                        border-radius: 0px 5px 5px 0px;
                        position: absolute;
                        width: 300px;
                        box-shadow: 2px 5px 5px rgba(0,0,0,0.19)
                    }
                }
            }
        }
    }

    @media (min-width: 1024px) {
        // display: flex;
        display: none;
        justify-content: space-between; 
        flex-direction: row-reverse;
        align-items: center;

        .logo-icon-menu {
            width: 20%
        }

        .main-menu {
            width: 80%;
            max-width: initial
        }
    }
}
</style>


